<template>
  <Button
    type="button"
    class="font-semibold loading-btn"
    @click="$emit('submit'), startLoading()"
  >
    <spinner v-if="loading" color="white" />
    <span v-if="!loading">{{ i18n.$t(title) }}</span>
  </Button>
</template>

<script>
import { GET_UI_LOADING, SET_UI_LOADING } from '@/store/types';
import { useStore } from 'vuex';
import Spinner from './Spinner';
import useI18n from '@/plugins/useI18n';
import {computed} from 'vue'
export default {
  props: {
    title: {
      type: String
    }
  },
  components: {
    Spinner
  },
  setup(props) {
    const {i18n} =  useI18n();
    const store = useStore();
    const loading = computed(() => store.getters[GET_UI_LOADING]);

    function startLoading() {
      store.commit(SET_UI_LOADING, true);
    }

    return {
      loading,
      startLoading,
      i18n
    };
  }
};
</script>

<style scoped>
.loading-btn {
  max-height: 40px;
}
</style>