<template>
  <Breadcrumb
    :home="breadcrumbHome"
    :model="breadcrumbItems"
    class="p-mt-4 p-mb-4"
  />
  <h3 class="font-bold text-3xl text-left">
    {{ `${i18n.$t('Invit')} ${i18n.$t('Employee')}` }}
  </h3>
  <div class="form-input p-mt-3">
    <p class="font-semibold text p-mt-3">{{ i18n.$t('Email') }}</p>
    <InputText
      v-model="employee.email"
      type="email"
      name="email"
      class="p-col-12 p-md-4 border border-teal-500 rounded p-1"
      required
    />
  </div>
  <div class="form-input p-mt-3">
    <p class="font-semibold text p-mt-3">{{ i18n.$t('Name') }}</p>
    <InputText
      v-model="employee.first_name"
      type="text"
      name="first_name"
      class="p-col-12 p-md-4 border border-teal-500 rounded p-1"
    />
  </div>

  <div class="form-input p-mt-3">
    <p class="font-semibold text p-mt-3">{{ i18n.$t('Role') }}</p>
    <Dropdown
      id="employee.role"
      class="p-col-12 p-md-4"
      v-model="employee.role"
      :options="roles"
      optionLabel="name"
      optionValue="code"
      placeholder="Select Role"
    ></Dropdown>
  </div>

  <div class="form-input p-mt-3">
    <ButtonSave title="Invit" @submit="onSave" />
  </div>
  <div class="p-mt-4">
    <transition-group name="p-messages" tag="div">
      <Message
        v-for="msg of message"
        :severity="msg.severity"
        :key="msg.content"
      >
        {{ msg.content }}
      </Message>
    </transition-group>
  </div>
</template>

<script>
import useI18n from '@/plugins/useI18n';
import { reactive, ref } from 'vue';
import { roles } from '@/store/constant';
import axios from '@/plugins/axios';
import { SET_UI_LOADING } from '@/store/types';
import { useStore } from 'vuex';
import ButtonSave from '@/components/UI/ButtonSave';
import EmployeeModel from '@/models/employee';
export default {
  data: () => ({
    breadcrumbHome: { icon: 'pi pi-home', to: '/' },
    breadcrumbItems: [{ label: 'Employee list', to: '/employee/list' }]
  }),
  components: {
    ButtonSave
  },
  setup() {
    const { i18n } = useI18n();
    const store = useStore();

    const employee = reactive(new EmployeeModel({}));

    const message = ref([]);
    async function onSave() {
      try {
        await axios.post('/user/invit', employee);
        message.value = [
          {
            severity: 'success',
            content: 'Invite email has been sent successfully'
          }
        ];
      } catch (err) {
        console.log(err);
        message.value = [
          { severity: 'error', content: 'Oops, something went wrong' }
        ];
      }
      store.commit(SET_UI_LOADING, false);
    }

    return {
      employee,
      i18n,
      roles,
      onSave,
      message
    };
  }
};
</script>